export default {
  name: 'Fernando Silva',
  position: 'Software Developer.',
  subtitle: 'In love technologies since I was a child. Currently, I help communities and Work in amazing Startup in São Paulo, Brazil.',
  skills: [
    { name: 'ReactJS', level: 80 },
    { name: 'NodeJS', level: 70 },
    { name: 'Tech Lead', level: 70 },
    { name: 'Typescript', level: 70 },
    { name: 'ELK Stack', level: 70 },
    { name: 'Docker', level: 60 },
    { name: 'AdonisJS', level: 60 },
    { name: 'React Native', level: 50 },
    { name: 'MongoDB', level: 50 },
    { name: 'GraphQL', level: 30 },
    { name: 'AWS', level: 30 },
    { name: 'Django', level: 30 }
  ]
};
