import styled from 'styled-components';
import React from 'react';
import media from 'styled-media-query';
import { FiGithub } from 'react-icons/fi';
import { FaLinkedinIn } from 'react-icons/fa';
import Layout from '../components/Layout';
import Skill from '../components/Skill';
import SEO from '../components/seo';
import personal from '../utils/personal';

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex: 1;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: space-between;
  padding-left: 3rem;

  ${media.lessThan('large')`
    flex-direction: column;
    padding: 5rem 1.5rem;
    align-items: center;
    overflow: auto;
  `}
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.brightOrange};
  font-size: 55px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const AboutMeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 40%;
  text-align: justify;
  background: rgba(0,0,0,0.7);
  padding: 20px;
  border-radius: 10px;
  ${media.lessThan('large')`
      max-width: 100%;
  `}
`;

const Description = styled.p`
  color: white;
  margin-top: 15px;
  font-weight: 500;
  line-height: 1.5;
`;

const SkillWrapper = styled.div`
  height: 100vh;
  margin-top: 0rem;
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${media.lessThan('large')`
    width: 100%;
    margin: 2rem 0;
  `}
`;

const ExternalLink = styled.a`
  color: ${({ theme }) => theme.orange};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.brightOrange};
  }
`;

const AboutPage = () => (
  <Layout>
    <SEO title="Sobre mim" />
    <Wrapper>
      <AboutMeWrapper>
        <Title>About Me</Title>
        <Description>
          Hello, my name is Fernando Silva, I’m a Full Stack Developer, but focused
          in React Ecosystem. I’m from São Paulo Brazil, get married with Talita and I have two
          cats,I love them! <br /> I’m passionate about technology since I was 6 years old, at the
          time I remember to help my school colleges using the computer, after 10 years,
          in my first job, again I helped my boss to use computers and peripherals...
          <br />Sure enough, I study PC maintained, database management, software developer
          and each time in almost 10 years of career I just applied my knowledge
          on many different situations, from my internship to current
          job and community ambassador.
          <br />
          <br />
          With you would like to talk with me, contact by{' '}
          <ExternalLink aria-label="e-mail" href="mailto:fernando@fdoors.com.br">
            E-mail
          </ExternalLink> or social networks{' '}
          <ExternalLink target="_blank" rel="noopener noreferrer" aria-label="linkedin" href="https://www.linkedin.com/in/fernandoors/">
            <FaLinkedinIn />
          </ExternalLink>{' '}
          <ExternalLink target="_blank" rel="noopener noreferrer" aria-label="github" href="https://github.com/fernandoors">
            <FiGithub />
          </ExternalLink>.
        </Description>
      </AboutMeWrapper>
      <SkillWrapper>
        {personal.skills.map((skill) => (
          <Skill key={skill.name} hability={skill} />
        ))}
      </SkillWrapper>
    </Wrapper>
  </Layout>
);

export default AboutPage;
